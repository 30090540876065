var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"config-panel"},_vm._l((_vm.configItems),function(item,index){return _c('div',{key:index,staticClass:"config-item"},[_c('div',{staticClass:"config-item-label"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('div',{staticClass:"config-item-radio"},_vm._l((item.timeoutOptions),function(minute,n){return _c('div',{key:n},[_c('div',{class:[
            'static',
            _vm.checkedTimeoutOptions[`timeoutOption${item.type.toString()}`] ==
            minute
              ? 'class-b'
              : 'class-a',
          ],on:{"click":function($event){_vm.toggle(`timeoutOption${item.type.toString()}`, minute)}}}),_c('span',{staticClass:"time_"},[_vm._v(_vm._s(_vm.minuteToHour(minute)))])])}),0),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.setAssignTimeoutConfig(item.type)}}},[_vm._v(" 保存 ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }