<template>
  <div class="config-panel">
    <div v-for="(item, index) in configItems" class="config-item" :key="index">
      <div class="config-item-label">
        {{ item.text }}
      </div>
      <div class="config-item-radio">
        <div v-for="(minute, n) in item.timeoutOptions" :key="n">
          <div
            :class="[
              'static',
              checkedTimeoutOptions[`timeoutOption${item.type.toString()}`] ==
              minute
                ? 'class-b'
                : 'class-a',
            ]"
            @click="toggle(`timeoutOption${item.type.toString()}`, minute)"
          ></div>
          <span class="time_">{{ minuteToHour(minute) }}</span>
        </div>
      </div>
      <button
        class="btn btn-primary"
        @click="setAssignTimeoutConfig(item.type)"
      >
        保存
      </button>
    </div>
  </div>
</template>
<script>
import {
  setTicketTimeoutUrl,
  getTicketTimeoutUrl,
  queryDepartmentUrl,
} from "@/requestUrl";
import { ORG_LEVEL } from "@/constant";
export default {
  props: {},
  components: {},
  data() {
    return {
      isA: false,
      configItems: [
        {
          text: "公共区域清洁, 保洁主管审批时限",
          timeoutOptions: [5, 10, 15, 20],
          type: [1],
        },
        {
          text: "公共区域保修, 工程主管审批时限",
          timeoutOptions: [5, 10, 15, 20],
          type: [2],
        },
        {
          text: "物品进出, 保安主管审批时限",
          timeoutOptions: [5, 10, 15, 20],
          type: [3],
        },
        {
          text: "其它、投诉, 综合主管审批时限",
          timeoutOptions: [5, 10, 15, 20],
          type: [4,5],
        },
        {
          text: "气窗开启, 工程主管审批时限",
          timeoutOptions: [5, 10, 15, 20],
          type: [6],
        },
        {
          text: "上门维修, 工程主管审批时限",
          timeoutOptions: [5, 10, 15, 20],
          type: [8],
        },
        {
          text: "公共区域秩序, 保安主管审批时限",
          timeoutOptions: [5, 10, 15, 20],
          type: [7],
        },
        /*
                TODO: 暂时隐藏
                {
                    text:"异常，保安主管指派时限",
                    timeoutOptions: [5, 10, 15, 20],
                    type: [9],
                }*/
      ],
      checkedTimeoutOptions: {},
    };
  },

  mounted() {
    this.configItems.forEach((val) => {
      this.getAssignTimeoutConfig(val.type);
    });
  },
  methods: {
    setAssignTimeoutConfig(type) {
      const timeout =
        this.checkedTimeoutOptions[`timeoutOption${type.toString()}`];
      type.forEach(async (val, key) => {
        const res = await this.editAssignTimeoutRequest(val, timeout);

        if (key === type.length - 1) {
          this.$vc.toast(res.code === 0 ? "设置成功" : res.msg);
        }
      });
    },
    /**
     * 获取部门列表
     * @param {Number} dutyType 部门类型
     * @param {String | Number} parentCode 公司id
     *  */
    getDeprtmentList(dutyType, parentCode) {
      return this.$fly
        .post(queryDepartmentUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          code: "",
          name: "",
          dutyType,
          parentCode,
          level: ORG_LEVEL.DEPARTMENT,
          pageNo: 1,
          pageSize: 100,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          return res.data;
        });
    },
    async getAssignTimeoutConfig(type) {
      let timeoutOption = `timeoutOption${type.toString()}`;
      type = type[0];
      const orderTypeMapping = {
        1: 1005,
        2: 1003,
        3: 1006,
        4: 1004,
        5: 1004,
        6: 1003,
        7: 1006,
        8: 1003,
        9: 1006,
      };
      let departmentInfo = await this.getDeprtmentList(
        orderTypeMapping[type],
        this.$vc.getCurrentStaffInfo().companyCode
      );
      departmentInfo = departmentInfo[0];
      if (!departmentInfo) {
        return;
      }

      this.$fly
        .post(getTicketTimeoutUrl, {
          regionId: this.$vc.getCurrentRegion().communityId,
          orgId: departmentInfo.code || "",
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          res.data.forEach((data) => {
            if (data.type == type) {
              this.$set(
                this.checkedTimeoutOptions,
                timeoutOption,
                data.timeoutSeconds / 60
              );
            }
          });
        });
    },
    async editAssignTimeoutRequest(type, timeout) {
      const orderTypeMapping = {
        1: 1005,
        2: 1003,
        3: 1006,
        4: 1004,
        5: 1004,
        6: 1003,
        7: 1006,
        8: 1003,
        9: 1006,
      };
      let departmentInfo = await this.getDeprtmentList(
        orderTypeMapping[type],
        this.$vc.getCurrentStaffInfo().companyCode
      );

      departmentInfo = departmentInfo[0];
      if (!departmentInfo) {
        return;
      }

      return this.$fly
        .post(setTicketTimeoutUrl, {
          orgId: departmentInfo.code || "",
          type,
          timeoutSeconds: timeout * 60,
          regionId: this.$vc.getCurrentRegion().communityId,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }

          return res;
        })
        .catch((err) => {
          return err;
        });
    },
    minuteToHour(minute) {
      if (minute < 60) {
        return minute + "分钟";
      }
      return (
        parseInt(minute / 60) +
        "小时" +
        (minute % 60 > 0 ? (minute % 60) + "分钟" : "")
      );
    },
    toggle: function (propertyName, val) {
      this.$set(this.checkedTimeoutOptions, propertyName, val);
      console.log(propertyName, val);
    },
  },
};
</script>
<style lang="stylus" scoped>
.config {
  &-panel {
    width: 100%;
    background: #fff;
    height: 100%;
    min-height: 500px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &-item {
    padding: 15px 30px;
    border-bottom: 1px solid #F0F0F0;

    div {
      display: inline-block;
    }

    &-label {
      margin-right: 30px;

      span {
        vertical-align: middle;
      }
    }

    button {
      margin-left: 30px;
    }
  }
}

.config-item-radio {
  margin-left: 30px;
}

.class-a {
  position: relative;
  top: 7px;
  left: 0;
  display: inline;
  width: 20px;
  height: 20px;
  background: url('../assets/icon5.png');
}

.class-b {
  position: relative;
  top: 7px;
  left: 0;
  display: inline;
  width: 20px;
  height: 20px;
  background: url('../assets/icon3.png');
}

.static {
  margin: 0 10px;
}

.config-item-label {
  width: 190px;
}

.time_ {
  display: inline-block;
  width: 43px !important;
}
</style>
